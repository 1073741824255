<template>
  <div>
    <ul class="content-row" :style="{ height: renderData.rowHeight && '78px' }" @click="handleRowClick">
      <li
        class="content-row__item"
        :class="[`content-row__item--${col.type}`]"
        :style="{ flex: col.width ? 'none' : '1', width: col.width, 'text-align': col.align }"
        v-for="(col, idx2) of columns"
        :key="col.id || col.key"
      >
        <template v-if="col.type === 'label'">
          <div class="content-row__item__cell" :class="[{ 'content-row__item__cell--padding-left': idx2 === 0 }]">
            <template v-if="col.render">
              <component
                :is="col.render"
                v-bind="{ data, column: col, idx: idx1, expandStatus }"
                @expand="handleExpand"
              />
            </template>
            <template v-else>
              <span class="content-row__item__text">{{ data[col.key] }}</span>
            </template>
          </div>
        </template>
        <template v-if="col.type === 'checkbox'">
          <div class="content-row__item__checkbox">
            <n-checkbox v-model:checked="isCheck" />
          </div>
        </template>
        <!-- 刪掉下面這個，用render function -->
        <template v-if="col.type === 'action'">
          <!-- <img src="@/assets/myMusic/icon_more.svg" /> -->
          <more />
        </template>
      </li>
    </ul>

    <div class="desc" v-if="renderData.moreDesc">
      <p
        class="desc__text"
        :class="{
          'desc__text--more': renderData.expandDetail === 'music-detail' && expandStatus.music,

        }"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, commodi reprehenderit quaerat incidunt tempore
        aperiam pariatur voluptate suscipit excepturi perferendis!
      </p>
    </div>

    <div class="extra-info" :class="{ 'extra-info--is-search': renderData.isSearch }" v-if="renderData.showExtraInfo">
      <div class="desc-extra" v-show="listFilter.includes('description')">
        <p class="desc-extra__text" :class="{'desc-extra__text--no-spacing': noPaddingPage}">
          Circular flow of warm energy. Emotional soulful wordless vocal with piano arpeggios and rhythmic backing
          vocals.
        </p>
      </div>
      <div class="sound-wave" v-show="listFilter.includes('waves')">
        <img class="sound-wave__img" src="@/assets/musicPlayer/sound-wave.png" />
      </div>
      <div class="label-items" v-show="listFilter.includes('tags')">
        <label-item v-for="l of labelWith" :label="l" :key="l" isSearch />
      </div>
    </div>

    <n-collapse-transition :show="renderData.expandDetail === 'list-detail' && expandStatus.detail">
      <list-detail />
    </n-collapse-transition>
    <n-collapse-transition :show="renderData.expandDetail === 'music-detail' && expandStatus.music">
      <music-detail />
    </n-collapse-transition>
    <n-collapse-transition :show="renderData.expandDetail === 'note-list' && expandStatus.note">
      <note-list />
    </n-collapse-transition>
  </div>
</template>

<script>
import {
  defineComponent, ref, inject, provide, reactive, computed,
} from 'vue';
import { NCheckbox, NCollapseTransition } from 'naive-ui';
import { useRoute } from 'vue-router';
import ListDetail from '@/components/MyMusic/MyMusicList/ListDetail/index.vue';
import More from '@/components/Global/MusicPlayer/Fragment/More.vue';
import MusicDetail from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/index.vue';
import NoteList from '@/components/MyMusic/MyMusicList/NoteList.vue';
import LabelItem from '@/components/Global/LabelItem.vue';
import { useSiteStore } from '@/store/site';
import { useMyMusicStore } from '@/store/myMusic';

export default defineComponent({
  name: 'ListContentRow',
  components: {
    ListDetail,
    NCheckbox,
    More,
    MusicDetail,
    NoteList,
    LabelItem,
    NCollapseTransition,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Object,
      default: () => ({}),
    },
    idx1: {
      type: Number,
    },
  },
  setup() {
    const route = useRoute();
    const siteStore = useSiteStore();
    const myMusicStore = useMyMusicStore();
    const renderData = inject('listContentConfig', {});
    const musicDetail = inject('musicDetail', {});

    const expandStatus = reactive({
      detail: false,
      music: false,
      note: false,
    });

    const handleExpand = (key) => {
      expandStatus[key] = !expandStatus[key];
    };

    provide('handleExpand', handleExpand);
    provide('expandStatus', () => expandStatus);

    const listFilter = computed(() => {
      let formatRouteName = '';
      if (route.name === 'search') {
        formatRouteName = `search-${route.query.tab}`;
      } else {
        formatRouteName = route.name.replace('my-music-', '');
      }

      return myMusicStore[formatRouteName].listFilter;
    });

    const labelWith = ['勵志 Inspirational', '孩子般 Childlike'];

    const handleRowClick = () => {
      // sm-pc 以上透過小箭頭展開
      if (siteStore.deviceType.includes('sm-pc')) return;

      console.log('mobile-music-player', musicDetail.value.mobileExpandKey);

      if (musicDetail.value.mobileExpandKey === 'mobile-music-player') {
        siteStore.toggleShowMobileMusicPlayer(true);

        return;
      }

      handleExpand(musicDetail.value.mobileExpandKey);
    };

    const noPaddingPage = computed(() => ['search', 'my-music-playlists', 'my-music-search-history', 'my-music-play-history', 'my-music-download-history'].includes(route.name));

    return {
      handleExpand,
      handleRowClick,
      expandStatus,
      isCheck: ref(false),
      renderData,
      listFilter,
      labelWith,
      noPaddingPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.content-row {
  @include flex();
  width: 100%;
  height: 52px;
  @include padding(0 16px);
  cursor: pointer;

  &__item {
    @include flex();

    &__text {
      @include font-style($c-assist6, 14, normal, 0, 26px);
    }

    &__cell {
      width: 100%;

      &--padding-left {
        margin-left: 0px;
        width: 100%;
      }
    }

    &__checkbox {
      width: 100%;
      @include flex(center);
    }
  }
}

.extra-info {
  @include padding(0 120px 0px 46px);

  &--is-search {
    @include padding(0 60px 0px 130px);

    // .desc__text {
    //   padding: 0;
    // }

    // .sound-wave {
    //   padding: 0;
    // }
  }
}

.desc {
  // margin-top: 12px;
  padding-bottom: 16px;

  &__text {
    @include font-style($c-assist4, 14, normal, 0.8px, 18px);
      margin-left: 55px;

  }
}

.desc-extra {
  // margin-top: 12px;
  padding-bottom: 16px;

  &__text {
    @include font-style($c-assist4, 14, normal, 0.8px, 18px);
      margin-left: 50px;

    &--no-spacing {
      margin-left: 0;
    }
  }
}

.sound-wave {
  @include padding(0 0px 16px);

  &__img {
    width: 100%;
    height: 50px;
  }
}

.label-items {
  @include padding(0 0px 16px);
}

.desc {
  display: none;
}

@media screen and (min-width: $sm-pc) {
  .content-row {
    min-height: 78px;
    height: auto;
    padding: 0;
    cursor: auto;

    &__item {
      &__cell {
        &--padding-left {
          margin-left: 20px;
        }
      }
    }
  }

  .desc {
    display: inline-block;
    @include padding(0 20px 20px 0);

    &__text {
      @include font-style($c-assist4, 14, 400, 0.7px, 18px);
      @include word-ellipsis(1);
      // overflow: hidden;
      // -o-text-overflow: ellipsis;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;

      &--more {
        @include word-ellipsis-unlimited();
        // overflow: visible;
        // -o-text-overflow: clip;
        // text-overflow: clip;
        // -webkit-line-clamp: none;
        // -webkit-box-orient: inline-axis;
      }
    }
  }
}
</style>
