<template>
  <div class="c-content-detail">
    <music-detail-header  v-model:activeItem="activeItem" />
    <div class="music-detail-wrap">
      <music-detail-version-and-edit
        :columns="musicDetail.versionAndEdit.columns"
        :contentData="musicDetail.versionAndEdit.data"
        v-if="activeItem === 'version-and-edit'"
      />
      <track-info :data="trackInfoMockData" v-if="activeItem === 'track-info'" />
      <lyric :data="lyricMockData" v-if="activeItem === 'lyric'" />
      <track-divide
        :columns="musicDetail.track.columns"
        :contentData="musicDetail.track.data"
        v-if="activeItem === 'track-divide'"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, inject } from 'vue';
import MusicDetailHeader from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/MusicDetailHeader.vue';
import MusicDetailVersionAndEdit from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/MusicDetailVersionAndEdit.vue';
import TrackInfo from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/TrackInfo.vue';
import Lyric from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/Lyric.vue';
import TrackDivide from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/TrackDivide.vue';

// import {
//   musicDetailColumns, musicDetailContentData, trackDivideColumns, trackDivideData,
// } from '@/config/myMusic/playlistsConfig';

export default defineComponent({
  name: 'MusicContentDetail',
  components: {
    MusicDetailHeader,
    MusicDetailVersionAndEdit,
    TrackInfo,
    Lyric,
    TrackDivide,
  },
  setup() {
    const activeItem = ref('version-and-edit');
    // const activeItem = ref('track-divide');

    const musicDetail = inject('musicDetail');

    const trackInfoMockData = [
      {
        title: 'Album',
        data: ['Beyond The Clouds'],
      },
      {
        title: 'Composer',
        data: ['Abbie Lathe [ PRS ] ', 'Al Lethbridge [ PRS ]'],
      },
      {
        title: 'Publisher',
        data: ['BBC Production Music [ PRS ]'],
      },
      {
        title: 'Labels',
        data: ['BBC Production Music'],
      },
    ];

    const lyricMockData = [
      ['I’m The One'],
      ['I’m about to drive em crazy', 'imma do imma do imma do what I want', 'What I want'],
      ['Think I wanna', 'Quit for the day Imma go on a really long drive', 'Cuz I can', 'Yeah, I bought this ride'],
    ];

    return {
      // musicDetailColumns,
      // musicDetailContentData,
      activeItem,
      trackInfoMockData,
      lyricMockData,
      // trackDivideColumns,
      // trackDivideData,
      musicDetail,
    };
  },
});
</script>
<style lang="scss" scoped>
.music-detail-wrap {
  background-color: $c-assist2;
  @include padding(25px 0);
}
</style>
