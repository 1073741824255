<template>
  <div class="c-my-music-list-header-detail-header">

    <div class="c-my-music-list-header-detail-header__content">
      <ul class="header-list">
        <template v-for="(col, idx) of columns" :key="col.label">
          <li
            class="header-list__item"
            :class="`header-list__item--${col.type}`"
            :style="{ flex: col.width ? 'none' : '1', 'width': col.width, 'text-align': col.align }"
          >
            <template v-if="col.type === 'label'">
              <div :class="{'header-list__item__padding-left': idx ===0}">
              {{ col.label }}
              </div>
            </template>
            <template v-if="col.type === 'checkbox'">
            <div class="center">
              <n-checkbox v-model:checked="isCheck" />
              </div>
            </template>
            <template v-if="col.type === 'action'">
              <img src="@/assets/myMusic/icon_more.svg" />
            </template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NCheckbox } from 'naive-ui';

export default defineComponent({
  name: 'MyMusicListHeader',
  components: {
    NCheckbox,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      isCheck: ref(false),
    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-header-detail-header {
  display: none;
  background-color: $c-white3;
  border-bottom: 1px solid $c-assist7;

  &__content {
    width: 100%;
    border-top: 1px solid $c-assist2;
    // @include max-width(1330);
  }
}
.header-list {
  width: 100%;
  height: 40px;
  @include flex();
  // @include padding(16px 0);

  &__item {
    @include font-style($c-assist4, 14, medium, 0.7px, 20px);

    &__padding-left {
      @include padding(0 0 0 20px);
      width: 100%;
    }
  }
}

.center {
  @include flex(center);
}

@media screen and (min-width: $sm-pc) {
  .c-my-music-list-header-detail-header {
    display: block;
  }
}
</style>
