<template>
  <div class="c-my-music-list-content-detail-content">
    <div class="c-my-music-list-content-detail-content__content">
      <ul class="content-list">
        <li class="content-list__item" v-for="(data, idx1) of contentData" :key="data.key">
          <list-detail-content-row :columns="columns" :data="data" :idx1="idx1" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import ListDetailContentRow from '@/components/MyMusic/MyMusicList/ListDetail/ListDetailContentRow.vue';

export default defineComponent({
  name: 'MyMusicListDetailContent',
  components: {
    ListDetailContentRow,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    contentData: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-content-detail-content {
  &__content {
    // margin-top: 34px;
  }
}

.content-list {
  &__item {
    background-color: $c-white3;

    &+& {
      border-top: 1px solid $c-assist7
    }
  }
}

.content-row {
  width: 100%;
  height: 80px;
  @include flex();

  &__item {
    @include flex();

    &__text {
      @include font-style($c-black, 14, normal, 0, 26px);
    }

    &__padding-left {
      padding-left: 20px;
      width: 100%;
    }

    &__checkbox {
      width: 100%;
      @include flex(center);
    }
  }
}
</style>
