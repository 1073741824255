<template>
  <div class="c-my-music-list">

      <list-header :columns="config.columns" v-if="showHeader" />

      <list-content :columns="config.columns" :contentData="contentData" :padding="padding" :max100="max100" />

  </div>
</template>
<script>
import { defineComponent } from 'vue';
import ListHeader from '@/components/MyMusic/MyMusicList/ListHeader.vue';
import ListContent from '@/components/MyMusic/MyMusicList/ListContent.vue';

export default defineComponent({
  name: 'MyMuiscList',
  components: {
    ListHeader,
    ListContent,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    contentData: {
      type: Array,
      default: () => [],
    },
    notice: {
      type: String,
      default: '',
    },
    padding: {
      type: Boolean,
      default: true,
    },
    max100: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list {
  background-repeat: no-repeat;
  background-position: top right;
  // background-image: url('~@/assets/myMusic/bg_deco.png');
  width: 100%;
  height: 100%;

}
</style>
