<template>
  <div class="c-playlists-name-expand-function">
    <div class="cover-name">
      <img class="cover-name__cover" src="@/assets/explore/playlists_img.jpg" />
      <p class="cover-name__name">Bags of Fun222</p>
    </div>

  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlaylistsNameExpandFunction',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
    },
  },
});
</script>
<style lang="scss" scoped>
.c-playlists-name-expand-function {
  width: 100%;
  @include flex();
}

.cover-name {
  flex: none;
  // width: 300px;
  @include flex();

  &__cover {
    width: 30px;
    height: 30px;
  }

  &__name {
    margin-left: 16px;
    @include font-style($c-black, 16, bold, 0.8px, 26px);
  }
}

</style>
