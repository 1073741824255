<template>
  <div class="c-my-music-list-header-detail-header">
    <div class="c-my-music-list-header-detail-header__content">
      <ul class="menu-list">
        <li
          class="menu-list__item"
          :class="{ 'menu-list__item--active': menu.key === activeItem }"
          v-for="menu of menuItems"
          :key="menu.label"
          @click="handleActive(menu.key)"
        >
          <p class="menu-list__item__text">
            {{ menu.label }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MyMusicListHeader',
  props: {

    activeItem: {
      type: String,
      default: '',
    },

  },
  emits: ['update:activeItem'],

  setup(props, { emit }) {
    const menuItems = [
      {
        key: 'version-and-edit',
        label: '版本和剪輯',
      },
      {
        key: 'track-info',
        label: '曲目資訊',
      },
      {
        key: 'lyric',
        label: '歌詞',
      },
      {
        key: 'track-divide',
        label: '分軌',
      },
    ];

    const handleActive = (key) => {
      emit('update:activeItem', key);
    };
    return {
      isCheck: ref(false),
      handleActive,
      menuItems,

    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-header-detail-header {
  background-color: $c-assist2;
  // border-bottom: 1px solid $c-assist7;

  &__content {
    width: 100%;
    // @include max-width(1330);
    @include padding(0 20px);
  }
}
.header-list {
  width: 100%;
  height: 40px;
  @include flex();

  &__item {
    @include font-style($c-assist4, 14, medium, 0.7px, 20px);

    &__padding-left {
      @include padding(0 0 0 20px);
      width: 100%;
    }
  }
}

.menu-list {
  width: 100%;
  @include flex();
  border-bottom: 1px solid rgba(#A0A0A0, 0.5);
  @include padding(20px 0 14px);

  &__item {
    position: relative;
    cursor: pointer;

    &+& {
      margin-left: 32px;
    }

    &:hover {
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: $c-main;
        display: inline-block;
        @include position(tl, calc(100% + 13px), 0);
      }

      .menu-list__item__text {
        color: $c-black;
      }
    }

    &--active {
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: $c-main;
        display: inline-block;
        @include position(tl, calc(100% + 13px), 0);
      }

      .menu-list__item__text {
        color: $c-black;
      }
    }

    &__text {
      @include font-style($c-assist4, 14, normal, 0.7px, 20px);
    }
  }
}
</style>
