<template>
  <ul class="content-row">
    <template v-for="(col, idx2) of columns" :key="col.key">
      <li
        class="content-row__item"
        :class="[`content-row__item--${col.type}`]"
        :style="{ flex: col.width ? 'none' : '1', width: col.width, 'text-align': col.align }"
      >
        <template v-if="col.type === 'label'">
          <div class="content-row__item__cell" :class="[{ 'content-row__item__cell--padding-left': idx2 === 0 }]">
            <template v-if="col.render">
              <component :is="col.render" v-bind="{ data, column: col, idx: idx1 }" />
            </template>
            <template v-else>
              <span class="content-row__item__text">{{ data[col.key] }}</span>
            </template>
          </div>
        </template>
        <template v-if="col.type === 'checkbox'">
          <div class="content-row__item__checkbox">
            <n-checkbox v-model:checked="isCheck" />
          </div>
        </template>
        <template v-if="col.type === 'action'">
          <detail-more />
        </template>
      </li>
    </template>
  </ul>
  <div class="extra-info" :class="{ 'extra-info--is-search': renderData.isSearch }">
    <div class="desc" v-show="listFilter.includes('description')">
      <p class="desc__text">
        Circular flow of warm energy. Emotional soulful wordless vocal with piano arpeggios and rhythmic backing vocals.
      </p>
    </div>
    <div class="sound-wave" v-show="listFilter.includes('waves')">
      <img class="sound-wave__img" src="@/assets/musicPlayer/sound-wave.png" />
    </div>
    <div class="label-item" v-show="listFilter.includes('tags')">
      <label-item v-for="l of labelWith" :label="l" :key="l" isSearch />
    </div>
  </div>
  <div v-if="expandInnerMusicStatus">
    <music-detail />
  </div>
</template>
<script>
import {
  defineComponent, ref, computed, inject, provide,
} from 'vue';
import { NCheckbox } from 'naive-ui';
import { useRoute } from 'vue-router';
import MusicDetail from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/index.vue';
import DetailMore from '@/components/Global/MusicPlayer/Fragment/DetailMore.vue';
import MusicProgress from '@/components/Global/MusicPlayer/MusicProgress.vue';
import LabelItem from '@/components/Global/LabelItem.vue';
import { useMyMusicStore } from '@/store/myMusic';

export default defineComponent({
  name: 'ListDetailContentRow',
  components: {
    NCheckbox,
    MusicDetail,
    DetailMore,
    MusicProgress,
    LabelItem,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    columns: {
      type: Object,
      default: () => ({}),
    },

    idx1: {
      type: Number,
    },
  },
  setup() {
    const route = useRoute();
    const myMusicStore = useMyMusicStore();
    const renderData = inject('listContentConfig', {});

    const expandInnerMusicStatus = ref(false);

    const handleInnerMusicExpand = () => {
      expandInnerMusicStatus.value = !expandInnerMusicStatus.value;
    };

    provide('expandInnerMusicStatus', () => expandInnerMusicStatus.value);
    provide('handleInnerMusicExpand', handleInnerMusicExpand);

    const listFilter = computed(() => {
      let formatRouteName = '';
      if (route.name === 'search') {
        formatRouteName = `search-${route.query.tab}`;
      } else {
        formatRouteName = route.name.replace('my-music-', '');
      }

      return myMusicStore[formatRouteName].listFilter;
    });

    const labelWith = ['勵志 Inspirational', '孩子般 Childlike'];

    return {
      isCheck: ref(false),
      listFilter,
      renderData,
      expandInnerMusicStatus,
      labelWith,
    };
  },
});
</script>
<style lang="scss" scoped>
.content-row {
  width: 100%;
  height: 56px;
  @include flex();
  @include padding(0 16px);

  &__item {
    @include flex();

    &__text {
      @include font-style($c-assist6, 14, normal, 0, 26px);
    }

    &__padding-left {
      padding-left: 20px;
      width: 100%;
    }

    &__cell {
      width: 100%;

      &--padding-left {
        margin-left: 0px;
        width: 100%;
      }
    }

    &__checkbox {
      width: 100%;
      @include flex(center);
    }
  }
}

.extra-info {
  @include padding(0 120px 0px 65px);

  &--is-search {
    @include padding(0 60px 0px 130px);

    // .desc__text {
    //   padding: 0;
    // }

    // .sound-wave {
    //   padding: 0;
    // }
  }
}

.desc {
  // margin-top: 12px;
  padding-bottom: 16px;

  &__text {
    @include font-style($c-assist4, 14, normal, 0.8px, 18px);
  }
}

.sound-wave {
  @include padding(0 0px 16px);

  &__img {
    width: 100%;
    height: 50px;
  }
}

.label-items {
  @include padding(0 0px 16px);
}

@media screen and (min-width: $sm-pc) {
   .content-row {
    height: 80px;
    @include padding(0 0px);

    &__item {
      &__cell {
        &--padding-left {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
