<template>
  <div class="c-my-music-list-content">
    <div
      class="c-my-music-list-content__content g-content"
      :class="{
        'c-my-music-list-content__content--margin-top': renderData.notice === '',
        'c-my-music-list-content__content--padding': padding,
        'c-my-music-list-content__content--max-100': max100
      }"
    >
      <p class="notice" v-if="renderData.notice">{{ renderData.notice }}</p>
      <ul class="content-list">
        <li class="content-list__item" v-for="(data, idx1) of contentData" :key="data.key">
          <list-content-row :columns="columns" :data="data" :idx1="idx1" />
        </li>

      </ul>

    </div>
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue';
import ListContentRow from '@/components/MyMusic/MyMusicList/ListContentRow.vue';

export default defineComponent({
  name: 'MyMusicListContent',
  components: {
    ListContentRow,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    contentData: {
      type: Array,
      default: () => [],
    },
    onExpand: {},

    // albumsInfo, playlistsInfo 使用
    padding: {
      type: Boolean,
      default: true,
    },
    max100: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const renderData = inject('listContentConfig', {});
    return {
      renderData,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-content {
  &__content {
    margin-top: 0;

    &--margin-top {
      padding-top: 34px;
      margin-bottom: 60px;
    }

    // &--padding {
    //   padding: 0;
    // }

    &--max-100 {
      max-width:  100%;
    }
  }
}

.notice {
  @include font-style($c-assist3, 14, normal, 0.7px, 20px);
  @include padding(8px 0);
}

.content-list {
  &__item {
    background-color: $c-white;
    box-shadow: 0px 0px 10px rgba($c-black, 0.05);
    border-radius: 5px;

    & + & {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $sm-pc) {
  .notice {
    @include padding(20px 0);
  }
}
</style>
