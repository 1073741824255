<template>
  <div class="c-lyric">
    <div class="c-lyric__content">
      <div class="lyric-paragraph" v-for="(paragraph, idx1) of data" :key="idx1">
        <p class="lyric-text" v-for="(p, idx2) of paragraph" :key="p + idx2">
          {{ p }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Lyric',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
<style lang="scss" scoped>
.c-lyric {
  margin: 0 20px;
  height: 150px;
  overflow: auto;
  border-right: 1px solid $c-assist3;
}
.lyric-paragraph {
  & + & {
    margin-top: 30px;
  }
}

.lyric-text {
  @include font-style($c-assist6, 16, normal, 0.8px, 26px);
}
</style>
