<template>
  <div class="c-more">
    <img class="c-more__img" src="@/assets/myMusic/icon_more.svg" @click="handleExpandStatus"/>
    <n-collapse-transition :show="expandStatus">
    <div class="more-list-wrap">
      123
      <ul class="more-list">

        <li class="more-list__item" v-for="item of list" :key="item.key" @click="handleClick(item.key)">
          <span class="more-list__item__label">{{ item.label }}</span>
          <img v-if="item.icon" class="more-list__item__icon" src="@/assets/global/icon_arrow.svg" >
        </li>
      </ul>
    </div>
    </n-collapse-transition>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue';
import { NCollapseTransition } from 'naive-ui';

export default defineComponent({
  name: 'More',
  components: {
    NCollapseTransition,
  },

  setup() {
    const expandStatus = ref(false);
    const handleExpandStatus = () => {
      expandStatus.value = !expandStatus.value;
    };

    const list = [
      {
        key: 'to-album-page',
        label: '前往專輯頁面',
      },
      {
        key: 'to-track-page',
        label: '查看曲目筆記',
      },
      {
        key: 'add-to-playlist',
        label: '新增至播放序列',
      },
      {
        key: 'edit-playlist-name',
        label: '新增至歌單',
        icon: true,
      },
      {
        key: 'move-to-playlist',
        label: '搬移至歌單',
        icon: true,
      },

    ];

    const modelStatus = reactive({
      'to-album-page': false,
      'to-track-page': false,
      'add-to-playlist': false,
      'edit-playlist-name': false,
      'move-to-playlist': false,
    });

    const handleClick = (key) => {
      modelStatus[key] = true;
      handleExpandStatus();
    };

    return {
      modelStatus,
      handleClick,
      list,
      expandStatus,
      handleExpandStatus,
    };
  },
});
</script>
<style lang="scss" scoped>

.c-more {
  position: relative;

  &__img {
    cursor: pointer;
  }
}
.more-list-wrap {
  @include position(tl, 100%, 0);
  @include flex(center);
  @include padding(20px 0);
  width: 165px;
  background-color: $c-white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #00000029;
  z-index: 10;
}

.more-list {

  &__item {
    @include flex(space-between);
    @include font-style($c-assist4, 16, normal, 0.8px, 24px);
    cursor: pointer;

    &+& {
      margin-top: 12px;
    }

    &__label {

      margin-right: 15px;
    }

    &__icon {

    }
  }
}
</style>
