<template>
  <div class="c-note-list">
    <div class="c-note-list__content">
      <ul class="note-list">
        <li class="note-list__item" v-for="i of 3" :key="i">
          <div class="item-row">
            <div class="item-row__basic">
              <p class="item-row__basic__title">這是備註文字 123</p>
              <p class="item-row__basic__time">14 Sep 2021 11:24</p>
            </div>
            <div class="item-row__action">
              <div class="item-row__action__edit">
                <span class="item-row__action__edit__text">Edit</span>
              </div>
              <img class="item-row__action__delete" src="@/assets/searchResults/icon_popup_note_delete.svg" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NoteList',
});
</script>
<style lang="scss" scoped>
.c-note-list {
  background-color: $c-white3;

  &__content {
    height: 200px;
    overflow: auto;
  }
}

.note-list {
  &__item {
    @include padding(23px 20px);
    border-bottom: 1px solid $c-assist7;
  }
}

.item-row {
  @include flex(space-between);

  &__basic {
    &__title {
      @include font-style($c-black, 16, normal, 0.8px, 26px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 18px);
    }
  }

  &__action {
    @include flex();

    &__edit {
      @include padding(0 10px);
      @include flex(center);
      display: inline-block;
      background-color: $c-assist15;
      height: 26px;
      border-radius: 13px;
      cursor: pointer;

      &__text {
        @include font-style($c-white, 14, normal, 0.7px, 26px);
      }
    }

    &__delete {
      margin-left: 20px;
    }
  }
}

@media screen and (min-width: $sm-pc) {
  .c-note-list {
    @include padding(0 80px 0 0);
  }

  .note-list {
    &__item {
      @include padding(23px 0 23px 20px);
    }
  }
}
</style>
