<template>
  <div class="c-my-music-list-content-detail-content">
    <div class="c-my-music-list-content-detail-content__content">
      <ul class="content-list">
        <li class="content-list__item" v-for="(data, idx1) of contentData" :key="data.key">
          <ul class="content-row">
            <template v-for="(col, idx2) of columns" :key="col.key">
              <li
                class="content-row__item"
                :class="[`content-row__item--${col.type}`]"
                :style="{ flex: col.width ? 'none' : '1', width: col.width, 'text-align': col.align }"
              >
                <template v-if="col.type === 'label'">
                  <div :class="[{ 'content-row__item__padding-left': idx2 === 0 }]">
                    <template v-if="col.render">
                      <component :is="col.render" v-bind="{ data, column: col, idx: idx1 }" />
                    </template>
                    <template v-else>
                      <span class="content-row__item__text">{{ data[col.key] }}</span>
                    </template>
                  </div>
                </template>
                <template v-if="col.type === 'checkbox'">
                  <div class="content-row__item__checkbox">
                    <n-checkbox v-model:checked="isCheck" />
                  </div>
                </template>
                <template v-if="col.type === 'action'">
                  <img src="@/assets/myMusic/icon_more.svg" />
                </template>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NCheckbox } from 'naive-ui';

export default defineComponent({
  name: 'MyMusicMusicDetailVersionAndEdit',
  components: {
    NCheckbox,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    contentData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      isCheck: ref(false),
    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-content-detail-content {
  &__content {
  }
}

.content-list {
  &__item {
    & + & {
      padding-top: 25px;
    }
  }
}

.content-row {
  width: 100%;
  height: 24px;
  @include flex();

  &__item {
    @include flex();

    &__padding-left {
      padding-left: 20px;
      width: 100%;
    }

    &__checkbox {
      width: 100%;
      @include flex(center);
    }

    &__text {
      @include font-style($c-assist6, 14, normal, 0, 26px);
    }
  }
}
</style>
