<template>
  <div class="c-content-detail">
    <list-detail-header  :columns="detailConfig.listDetailColumns"/>
    <list-detail-content  :columns="detailConfig.listDetailColumns"  :contentData="detailConfig.listDetailContentData" />
  </div>
</template>
<script>
import { defineComponent, inject, computed } from 'vue';
import ListDetailHeader from '@/components/MyMusic/MyMusicList/ListDetail/ListDetailHeader.vue';
import ListDetailContent from '@/components/MyMusic/MyMusicList/ListDetail/ListDetailContent.vue';

export default defineComponent({
  name: 'ContentDetail',
  components: {
    ListDetailHeader,
    ListDetailContent,
  },
  setup() {
    // const detailConfig = inject('detailConfig', () => ({
    //   listDetailColumns: [],
    //   listDetailContentData: [],
    // }));

    const detailConfig = computed(() => {
      try {
        return inject('detailConfig')();
      } catch {
        return ({
          listDetailColumns: [],
          listDetailContentData: [],
        });
      }
    });

    return {

      detailConfig,
    };
  },
});
</script>
<style lang="scss" scoped>

</style>
