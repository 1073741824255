<template>
  <div class="c-playlists-name-expand-function">
    <div class="name">
      <p class="name__music-name">{{ data.name }}</p>
      <p v-show="data.extraInfo" class="name__extra-info">({{ data.extraInfo }})</p>
    </div>

  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VersionAndEditNameFunction',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
    },
  },
});
</script>
<style lang="scss" scoped>
.c-playlists-name-expand-function {
  width: 100%;
  @include flex();
  @include padding(0px 20px 0px 0);
  max-width: 500px;
}

.name {
  flex: none;
  width: 300px;
  @include flex();

  &__music-name {

    @include font-style($c-assist6, 16, normal, 0px, 26px);
  }

  &__extra-info {
    margin-left: 5px;
    @include font-style($c-assist4, 16, normal, 0px, 26px);
  }
}

</style>
