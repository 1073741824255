<template>
  <div
    class="c-my-music-list-header"
    :class="{
      'c-my-music-list-header--search': route.name === 'search',
    }"
  >
    <div class="c-my-music-list-header__content g-content">
      <ul class="header-list">
        <!-- <template > -->
          <li
          v-for="col of columns" :key="col.id || col.key"
            class="header-list__item"
            :class="`header-list__item--${col.type}`"
            :style="{ flex: col.width ? 'none' : '1', width: col.width, 'text-align': col.align }"
          >
            <template v-if="col.type === 'label'">
              <template v-if="col.renderHeader">
                <component :is="col.renderHeader" v-bind="{ data, column: col, idx: idx1, expandStatus }" />
              </template>
              <template v-else >
                {{ col.label }}
              </template>

              <!-- <template >
              1 -->
                <!-- <component v-if="col.renderHeader" :is="col.renderHeader" v-bind="{ data, column: col, idx: idx1, expandStatus }" />
                <span v-else>{{ col.label }}</span> -->
              <!-- </template>
              <template v-else >
               2
              </template> -->
            </template>
            <template v-if="col.type === 'checkbox'">
              <div class="center">
                <n-checkbox v-model:checked="isCheck" />
              </div>
            </template>
            <template v-if="col.type === 'action'">
              <!-- <img src="@/assets/myMusic/icon_more.svg" /> -->
            </template>
          </li>
        <!-- </template> -->
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NCheckbox } from 'naive-ui';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'MyMusicListHeader',
  components: {
    NCheckbox,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const route = useRoute();

    return {
      isCheck: ref(false),
      route,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-header {
  display: none;
  background-color: $c-white;
  box-shadow: 0px 3px 10px rgba($c-black, 0.05);

  &--search {
    background-color: transparent;
    box-shadow: 0px 0 0 transparent;
  }

  &__content {
    width: 100%;
    border-top: 1px solid $c-assist2;
  }
}
.header-list {
  width: 100%;
  @include flex(flex-start);
  @include padding(16px 0);

  &__item {
    @include font-style($c-assist4, 14, 500, 0.7px, 20px);
  }
}
.center {
  @include flex(center);
}

@media screen and (min-width: $sm-pc) {
  .c-my-music-list-header {
    display: block;
  }
}
</style>
